import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BrandList from "../components/brand-list/brand-list"
import { Flex, Text, Box, Card, Image, Heading } from "rebass"
import {globalHistory} from  "@reach/router";
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBrand {
        edges {
          node {
            slug
            name
          }
        }
      }
    }
  `)
  const brands = data.allContentfulBrand.edges
  return (
    <Layout>
      <SEO title="New Cars 2019 - Latest Promo and Models" />
      <section>
        <Card width={256}>
          <Heading>Carousel here</Heading>
        </Card>
      </section>

      <section>
        <Heading color="primary">Featured Model</Heading>
      </section>

      <section>
        <Heading> Featured Car Brands </Heading>
        <BrandList location={globalHistory.location} brands={brands} />
      </section>
    </Layout>
  )
}

export default IndexPage
